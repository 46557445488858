<template>
  <div class="enterpriseInfo">
    <h3>企业信息</h3>
    <br />
    <hr />
    <div style="margin-top: 50px"></div>
    <div class="item">
      <div class="label">
        <span >企业Logo</span></div>
      <div style="margin-left: 100px">
        <span
          ><img
            width="60px"
            height="60px"
            :src="companyInfo.log_img"
        /></span>
      </div>
    </div>

    <div class="item">
      <div class="label">
        <span >企业简称</span>
      </div>
      <div style="margin-left: 100px; display: flex">
        <span>{{ companyInfo.abbreviation }}</span>
        <!--
        <div style="display: flex; align-items: center">
          <p v-if="item.flag == 1" class="certification">{{ item.rz }}</p>
        </div>-->
      </div>
    </div>
    <div class="item">
      <div class="label">
        <span >企业全称</span>
      </div>
      <div style="margin-left: 100px; display: flex">
        <span>{{ companyInfo.company_name }}</span>
      </div>
    </div>

    <div class="item">
      <div class="label">
        <span >主体类型</span>
      </div>
      <div style="margin-left: 100px; display: flex">
        <span>{{ companyInfo.type==0?"民企":companyInfo.type==1?"国企":"私人企业" }}</span>
      </div>
    </div>


    <div class="item">
      <div class="label">
        <span >企业地址</span>
      </div>
      <div style="margin-left: 100px; display: flex">
        <span>{{ companyInfo.address }}</span>
      </div>
    </div>

    <div class="item">
      <div class="label">
        <span >联系人</span>
      </div>
      <div style="margin-left: 100px; display: flex">
        <span>{{ companyInfo.contact }}</span>
      </div>
    </div>

    <div class="item">
      <div class="label">
        <span >联系电话</span>
      </div>
      <div style="margin-left: 100px; display: flex">
        <span>{{ companyInfo.mobile }}</span>
      </div>
    </div>

    <div class="item">
      <div class="label">
        <span >企业成员</span>
      </div>
      <div style="margin-left: 100px; display: flex">
        <span>{{ companyInfo.number }}</span>
      </div>
    </div>

    <div class="item">
      <div class="label">
        <span >创业时间</span>
      </div>
      <div style="margin-left: 100px; display: flex">
        <span>{{ companyInfo.created_at }}</span>
      </div>
    </div>
    <div class="item">
      <div class="label">
        <span>企业ID</span>
      </div>
      <div style="margin-left: 100px; display: flex">
        <span>{{ companyInfo.open }}</span>
      </div>
    </div>




  </div>
</template>

<script>
  import { companyDetail} from "@/api/manage/index";

export default {

  data() {
    return {
     

      companyInfo:{
        log_img:"",//头像
        abbreviation:"",//简称
        company_name:"",
        type:"",//0 民企 1 国企 2 私人企业
        contact:"",//联系人
        mobile:"",//联系电话
        number:"",//公司人员
        created_at:"",//创业时间

      }
    };
  },
  mounted(){

    this.initInfo();
  },

  methods: {
    initInfo(){
      var that = this;
      companyDetail({}).then((response) => {
        console.log(response);
        if(response.code==200){
          that.companyInfo=response.data
        }else{
          
          that.$message.error(response.msg?response.msg:"请求错误");
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.enterpriseInfo {
  padding:20px ;
  background-color: white;
}
.certification {
  width: 50px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #4c71db;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 12px;
  color: #4c71db;
}

.item{
  border-bottom: 1px solid #f8f8f8; line-height: 60px; display: flex;
  .label{
    width: 64px;
    span{
      color: #808080
    }
  }


}
</style>
