var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enterpriseInfo"},[_c('h3',[_vm._v("企业信息")]),_c('br'),_c('hr'),_c('div',{staticStyle:{"margin-top":"50px"}}),_c('div',{staticClass:"item"},[_vm._m(0),_c('div',{staticStyle:{"margin-left":"100px"}},[_c('span',[_c('img',{attrs:{"width":"60px","height":"60px","src":_vm.companyInfo.log_img}})])])]),_c('div',{staticClass:"item"},[_vm._m(1),_c('div',{staticStyle:{"margin-left":"100px","display":"flex"}},[_c('span',[_vm._v(_vm._s(_vm.companyInfo.abbreviation))])])]),_c('div',{staticClass:"item"},[_vm._m(2),_c('div',{staticStyle:{"margin-left":"100px","display":"flex"}},[_c('span',[_vm._v(_vm._s(_vm.companyInfo.company_name))])])]),_c('div',{staticClass:"item"},[_vm._m(3),_c('div',{staticStyle:{"margin-left":"100px","display":"flex"}},[_c('span',[_vm._v(_vm._s(_vm.companyInfo.type==0?"民企":_vm.companyInfo.type==1?"国企":"私人企业"))])])]),_c('div',{staticClass:"item"},[_vm._m(4),_c('div',{staticStyle:{"margin-left":"100px","display":"flex"}},[_c('span',[_vm._v(_vm._s(_vm.companyInfo.address))])])]),_c('div',{staticClass:"item"},[_vm._m(5),_c('div',{staticStyle:{"margin-left":"100px","display":"flex"}},[_c('span',[_vm._v(_vm._s(_vm.companyInfo.contact))])])]),_c('div',{staticClass:"item"},[_vm._m(6),_c('div',{staticStyle:{"margin-left":"100px","display":"flex"}},[_c('span',[_vm._v(_vm._s(_vm.companyInfo.mobile))])])]),_c('div',{staticClass:"item"},[_vm._m(7),_c('div',{staticStyle:{"margin-left":"100px","display":"flex"}},[_c('span',[_vm._v(_vm._s(_vm.companyInfo.number))])])]),_c('div',{staticClass:"item"},[_vm._m(8),_c('div',{staticStyle:{"margin-left":"100px","display":"flex"}},[_c('span',[_vm._v(_vm._s(_vm.companyInfo.created_at))])])]),_c('div',{staticClass:"item"},[_vm._m(9),_c('div',{staticStyle:{"margin-left":"100px","display":"flex"}},[_c('span',[_vm._v(_vm._s(_vm.companyInfo.open))])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('span',[_vm._v("企业Logo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('span',[_vm._v("企业简称")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('span',[_vm._v("企业全称")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('span',[_vm._v("主体类型")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('span',[_vm._v("企业地址")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('span',[_vm._v("联系人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('span',[_vm._v("联系电话")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('span',[_vm._v("企业成员")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('span',[_vm._v("创业时间")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('span',[_vm._v("企业ID")])])
}]

export { render, staticRenderFns }